.PhoneInputInput {
    width: 100% !important;
    padding: 10px;
    font-size: 16px;
    border: none !important;
    outline: none !important;
    
    
  }
.MuiBox-root-4 {
    width: 50%;
    padding: 11px 40px 0px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 7px -1px;
    border-radius: 20px;
}
@media (max-width: 430px) {
    .MuiBox-root-4 {
      width: 100%; 
      margin-top: -104px;/* Change the width to 100% on screens with a width of 768px or less */
    }
  }