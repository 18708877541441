.menu-section .menu-text {
  color: black !important;
}
.aside-menu .menu-nav > .menu-section .menu-text {
  color: black !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #070459 !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: black !important;
}
.activeSubMenu > .ant-menu-submenu-title {
  color: #f2c744 !important; /* Change submenu title color to red when active */
}

.activeLink {
  color: #f2c744 !important; /* Change link color to red when active */
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
  color: black !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-text {
  color: #F2C744 !important;
}
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon {
  color: white !important;
}
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-icon {
  color: #070459 !important;
}
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-text {
  color: #070459 !important;
}
.text-black {
  color: black !important;
}
.a {
  color: #0060ff !important;
}

.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-primary:focus:not(.btn-text),
.btn.btn-primary.focus:not(.btn-text) {
  background-color: #F2C744 !important;
  border-color: #F2C744 !important;
  color:#010440 !important
}
.btn.btn-light-primary {
  border-color: #24addb !important;
  background-color: #00800030 !important;
  color: black !important;
}
.form-control.form-control-solid {
  background-color: #0080000a !important;
}
.scrolltop {
  background-color: #24addb !important;
}
.icons {
  margin-right: 14px;
  font-size: 20px;
}
.admin {
  margin-left: 45%;
  margin-top: -35px;
  margin-bottom: 12%;
  font-family: unset;
  font-size: 16px;
  font-weight: 600;
}
.clr {
  color: #24addb;
}
.list {
  background: #fff;
  color: #000000;
  list-style: none;
  margin: 0;
  font-size: 18px;
  overflow: hidden;
  height: 0px;
  transition: "height" 0.3s ease;
  padding: 0;
}
.list.active {
  height: 100px;
  overflow: auto;
}

sub {
  background-color: blue !important;
  transition: " background-color 0.3s ease " !important;
}

.sub:active {
  background-color: red !important;
}
.ant-menu-sub.ant-menu-inline {
  background: none !important;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  border: none !important;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #070459 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #070459 !important;
  color: #F2C744 !important;
  border-radius: 8px !important;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}

