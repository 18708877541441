.chart {
  width: 100%;
  height: 280px;
  font-size: 10px;
}
@media only screen and (max-width: 1440px) {
  /* .flex{
      width: 200px;     
    } */
  .chart {
    width: 100%;
    height: 200px;
    font-size: 9px;
  }
}
