.brdr {
  border: 1px solid rgb(57, 56, 56);
  border-radius: 50% 50%;
  width: 30px;
  padding: 0px;
  text-align: center;
  font-weight: 600;
  margin: 0 auto;
  font-size: 20px;
}
.ant-popover {
  max-width: fit-content;
  border-radius: 10px !important;
}
.ant-popover-inner {
  border-radius: 15px !important;
}
.fwt {
  font-weight: 600;
}
