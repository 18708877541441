
.dash{
    margin: 0;
}

@media only screen and (max-width: 425px) {

.dash{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
}