.btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-light.dropdown-toggle,
.show .btn.btn-light.btn-dropdown {
  background-color: #070459 !important;
  color: #F2D541 !important;
}

.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
  background-color: #070459 !important;
  color: #F2D541 !important;
}
.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btns-hover-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
  background-color: #070459!important;
  color: #F2D541 !important;
}
